/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-04",
    versionChannel: "nightly",
    buildDate: "2024-08-04T00:05:45.077Z",
    commitHash: "4d85307c471a5428399c790194bceebfc4155b3e",
};
